import axios from "axios";

const rootURL = "https://api.tudienviet.vn:8001";
const get = (url, callback) => {
	axios.get(rootURL + url)
		.then(res => callback(null, res.data))
		.catch(error => {
			error.response ?
			callback(error.response.data, null) : callback(error.message,null);
		})
};

const post = (url, body, callback) => {
	axios.post(rootURL + url, body)
		.then(res => callback(null, res.data))
		.catch(error => { error.response ?
			callback(error.response.data, null) : callback(error.message,null);
		})
};

const getByAuth = (url, callback) => {
	axios.get(rootURL + url, {
		headers: {
			"Authorization": `Token ${localStorage.getItem('token')}`
		}
	})
		.then(res => callback(null, res.data))
		.catch(error => {
			if(error.response){
				if(error.response.status === 401){
					localStorage.removeItem('token')
				}
				callback(error.response.data, null)
			} else {
				callback(error.message, null);
			}
		})

};

const postByAuth = (url, body, callback) => {
	axios.post(rootURL + url, body, {
		headers: {
			"Authorization": `Token ${localStorage.getItem('token')}`
		}
	})
		.then(res => callback(null, res.data))
		.catch(error => {
			if(error.response){
				if(error.response.status === 401){
					localStorage.removeItem('token')
				}
				callback(error.response.data, null)
			} else {
				callback(error.message, null);
			}
		})
};

const API = {
	get, post, getByAuth, postByAuth
};

export default API
